import { _ } from "vue-underscore";
import Multiselect from "vue-multiselect";

export default {
	data: () => ({
		cityGroups: {
			id: 0,
			name: "",
			description: "",
			cities: "",
			// optionsselection: []
		},
		selectedCity: [],
		citiesCodeList: [],
		selected: "",
		error: "",
		backUrl: "/citygroups",
		loading: false,
	}),
	components: {
		Multiselect
	},
	methods: {
		customLabel({ city, city_code }) {
			return `${city} – ${city_code}`
		},
		resetForm() {
			this.$refs.observer.reset();
			this.cityGroups = {
				id: 0,
				name: "",
				description: "",

			};
		},
		validateCityGroups() {
			this.cityGroups.id > 0 ? this.update() : this.add();
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 10000);
		},
		update() {
			let _vm = this;
			const cities = [];
			// Iterate over selectedCity array and push city codes to cities array
			this.selectedCity.forEach(element => {
				cities.push(element.city_code)
			});
			_vm.cityGroups.cities = cities.join(",");
			this.axios
				.post("/city-groups/" + this.cityGroups.id, {
					...this.cityGroups,
					_method: "PATCH"
				})
				.then(function (response) {
					_vm.cityGroups = response.data.data;
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		add() {
			let _vm = this;
			const cities = [];
			// Loop through each element in selectedCity array and push city_code to cities array
			this.selectedCity.forEach(element => {
				cities.push(element.city_code);
			});
			_vm.cityGroups.cities = cities.join(",");
			this.axios
				.post("/city-groups", _vm.cityGroups)
				.then(function (response) {
					_vm.$router.push(_vm.backUrl); // Redirect to the specified URL
				})
				.catch(function () {
					// Error handling
				});
		},

		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		getDetail() {
			let _vm = this;
			// Make a GET request to retrieve city groups
			this.axios
				.get("/city-groups/" + this.$route.params.id)
				.then(function (response) {
					_vm.cityGroups = response.data.data;
					_vm.selectedCity = [];
					// Split the cities string into an array
					const cities = _vm.cityGroups.cities.split(",");
					// Iterate over the cities code list
					_vm.citiesCodeList.forEach(element => {
						if (cities.indexOf(element.city_code) > -1) {
							// Add selected cities to the selectedCity array
							_vm.selectedCity.push({ 'city': element.city, 'city_code': element.city_code });
						}
					});
				})
				.catch(function () {
					// Handle any errors that occur during the request
				});
		},

		getCitieCode() {
			let _vm = this;
			const queryString = this.$route.params.id ? '?id=' + this.$route.params.id : '';
			// Make a GET request to fetch city groups and cities
			this.axios
				.get("/city-groups-cities" + queryString, _vm.citiesCodeList)
				.then(function (response) {
					_vm.citiesCodeList = response.data.data;
					// Check if the ID parameter is greater than 0
					if (parseInt(_vm.$route.params.id) > 0) {
						_vm.getDetail(); // Call the getDetail() function
					}
				})
				.catch(function () { });
		}

	},
	mounted() {

		this.getCitieCode()

	}
};
